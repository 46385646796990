<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>{{ customer.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col><h2>Outstanding invoices</h2></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Number</th>
              <th>Date</th>
              <th>Total</th>
              <th>Paid</th>
              <th>Retention</th>
              <th>Balance</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in outstandingInvoices" :key="`i_${index}`">
              <td>{{invoice.number }}</td>
              <td>{{ invoice.date | tinyDate }}</td>
              <td>{{ invoice.amount | priceInPounds | currency }}</td>
              <td>{{ invoice.paid | priceInPounds | currency }}</td>
              <td>{{ invoice.retention | priceInPounds | currency }}</td>
              <td>{{ invoice.balance | priceInPounds | currency }}</td>
              <td>
                <router-link :to="`/invoices/payment-history/${invoice.id}`">
                  <v-btn x-small>Enter payment</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="400"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <router-link :to="`/customers/view/${customer.id}`">
            <v-btn
              x-small
              color="orange darken-2">
              Back to customer details
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>  
</template>

<script>
import axios from '../../axios';

export default {
  name: 'AddContact',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      customer: {},
      outstandingInvoices: [],
      message: '',
      showMessage: false,
    };
  },
  methods: {
    getCustomer() {
      const customerId = this.$route.params.id;
      axios.get(`/customers/getForPayment/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.customer = response.data.customer;
          this.outstandingInvoices = response.data.outstandingInvoices;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCustomer();
  },
};
</script>
